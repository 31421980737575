"use client";

import { IconButton } from "@xenia-libs/xenia-ui/icon-button";
import { Icon, ArrowLeftIcon } from "@xenia-libs/xenia-ui/icon";
import {
  useSearchParams,
  useRouter,
} from "@xenia-libs/resident-app-utils/navigation";
import { MouseEventHandler, useCallback } from "react";
import { useParentPath } from "@xenia-libs/resident-app-hooks/use-parent-path";
import { useNavStore } from "../stores/navigation.store";
import {
  AvailableSearchParams,
  TruthySearchParam,
} from "@xenia-libs/resident-app-utils/types";
import { HEADER_ICON_SIZE } from "./constants/header-icon-size";
import { useTranslations } from "@xenia-libs/next-i18n";

export function BackButton() {
  const router = useRouter();
  const searchParams = useSearchParams();
  const { parentPath } = useParentPath();
  const fromResidentApp =
    searchParams.get(AvailableSearchParams.FROM_RESIDENT_APP) ===
    TruthySearchParam.TRUE;
  const { setNavOpen } = useNavStore();
  const t = useTranslations("features.navigation");

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
    setNavOpen(false);

    if (fromResidentApp) {
      router.back();
    } else if (parentPath) {
      const paramsStrings =
        searchParams.size > 0 ? `?${searchParams.toString()}` : "";

      router.push(`${parentPath}${paramsStrings}`);
    }
  }, [fromResidentApp, parentPath, router, searchParams, setNavOpen]);

  if (!parentPath) {
    return null;
  }

  return (
    <IconButton aria-label={t("go_back")} onClick={handleClick}>
      <Icon icon={ArrowLeftIcon} size={HEADER_ICON_SIZE} />
    </IconButton>
  );
}
