"use client";
import { Box, Stack } from "@xenia-libs/xenia-ui/layout";
import { IconButton } from "@xenia-libs/xenia-ui/icon-button";
import { Icon, CloseIcon, MenuIcon } from "@xenia-libs/xenia-ui/icon";
import { useTranslations } from "@xenia-libs/next-i18n";
import { MouseEventHandler, forwardRef } from "react";
import { BackButton } from "./back-button";
import { HEADER_ICON_SIZE } from "./constants/header-icon-size";

export type HeaderProps = {
  logoSrc: string | undefined;
  isNavOpen?: boolean;
  onMenuClick?: MouseEventHandler<HTMLButtonElement>;
  brandName: string;
  height?: string;
};

export const Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ height, logoSrc, onMenuClick, brandName, isNavOpen }, ref) => {
    const t = useTranslations("features.navigation");

    return (
      <Stack
        ref={ref}
        component="header"
        direction="row"
        alignItems="center"
        position="sticky"
        height={height}
        top={0}
        p={1}
        px={2}
        sx={(theme) => ({
          background: theme.palette.primary.main,
          zIndex: theme.zIndex.appBar,
          color: theme.palette.primary.contrastText,
        })}
      >
        <Box flex={1}>
          <BackButton />
        </Box>
        <Stack alignItems="center" height="2rem" width="7rem">
          {!!logoSrc && (
            <Box
              component="img"
              src={logoSrc}
              alt={t("logo.alt", { brandName })}
              height="100%"
              data-testid="header-logo"
            />
          )}
        </Stack>
        <Stack flex={1} alignItems="end">
          <IconButton
            aria-label={t("toggle", { isOpen: isNavOpen })}
            onClick={onMenuClick}
            data-testid="header-menu-button"
            sx={(theme) => ({
              [theme.breakpoints.up("mobile")]: {
                display: "none",
              },
            })}
          >
            {isNavOpen ? (
              <Icon
                icon={CloseIcon}
                size={HEADER_ICON_SIZE}
                data-testid="menu-close-icon"
              />
            ) : (
              <Icon
                icon={MenuIcon}
                size={HEADER_ICON_SIZE}
                data-testid="menu-open-icon"
              />
            )}
          </IconButton>
        </Stack>
      </Stack>
    );
  },
);
