import { GetThemeComponents } from "../theme/theme.types";
import { ChevronRightIcon, Icon } from "../icon";
import { accordionClasses } from "@mui/material/Accordion";

// Assuming that white will work for all branding configurations.
// The only other obvious value we can use is the background
// in the theme, but that is typically used as the background
// for the entire app.
const BACKGROUND_COLOR = "white";

export const accordionTheme: GetThemeComponents<
  "MuiAccordion" | "MuiAccordionSummary" | "MuiAccordionDetails"
> = () => ({
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        background: BACKGROUND_COLOR,
        [`&.${accordionClasses.expanded}`]: {
          margin: 0,
        },

        // Keep the divider when expanded
        "&::before": {
          display: "none",
        },
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        "&:first-of-type": {
          borderTop: "none",
        },
        [`&.${accordionClasses.disabled}`]: {
          background: BACKGROUND_COLOR,
        },
      },
    },
  },
  MuiAccordionSummary: {
    defaultProps: {
      expandIcon: (
        <Icon
          icon={ChevronRightIcon}
          size="1.5em"
          sx={{ transform: "rotate(90deg)" }}
        />
      ),
    },
    styleOverrides: {
      root: ({ theme }) => ({
        minHeight: 48,
        fontSize: "1rem",
        fontFamily: "inherit",
        [`&.${accordionClasses.expanded}`]: {
          minHeight: 48,
        },
      }),
      content: {
        [`&.${accordionClasses.expanded}`]: {
          margin: "12px 0",
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: "0 16px 16px",
      },
    },
  },
});
