import { Box, BoxProps } from "../layout";
import { type IconType } from "react-icons";

export type IconProps = BoxProps & {
  icon: IconType;
  size?: string;
  testId?: string;
};

export function Icon({
  icon: IconComponent,
  size = "1em",
  testId,
  ...props
}: IconProps) {
  return (
    // Wrap in a Box to get all the MUI goodness
    <Box
      height={size}
      width={size}
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <IconComponent size="100%" data-testid={testId} />
    </Box>
  );
}
