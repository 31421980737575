"use client";
import { PropsWithChildren, createContext, useContext, useMemo } from "react";
import { ColorwayName, Theme, ThemeConfig } from "../theme.types";
import {
  ThemeProvider as MuiThemeProvider,
  useTheme,
} from "@mui/material/styles";
import { getMuiTheme } from "../theme";
import CssBaseline from "@mui/material/CssBaseline";

export const useMuiTheme = useTheme;

const BrandThemeContext = createContext<Theme | null>(null);
export const useBrandTheme = () => useContext(BrandThemeContext)!;

const ColorwayContext = createContext<ColorwayName | null>(null);
export const useColorway = () => useContext(ColorwayContext)!;

export type ThemeProviderProps = PropsWithChildren<
  Partial<Omit<ThemeConfig, "colorway">>
>;

/**
 * This provider takes a theme from a Chameleon brand and converts it into a MUI theme
 * to power the components in Xenia UI. Providers can be nested to allow for different
 * colorways to be used in different parts of the app.
 * @example
 * <ThemeProvider theme={theme} colorway={ColorwayName.Colorway1}>
 *    <Button color="primary">Primary color from colorway 1!</Button>
 *
 *    <ThemeProvider colorway={ColorwayName.Colorway2}>
 *      <Button color="primary">Primary color from colorway 2!</Button>
 *    </ThemeProvider>
 * </ThemeProvider>
 */
export function ThemeProvider({
  theme: providedTheme,
  children,
  colorwayName = ColorwayName.Colorway1,
}: ThemeProviderProps) {
  const existingBrandTheme = useBrandTheme();

  const theme = useMemo(() => {
    return providedTheme || existingBrandTheme;
  }, [existingBrandTheme, providedTheme]);

  const muiTheme = useMemo(() => {
    return getMuiTheme({
      theme,
      colorwayName,
      colorway: theme?.colorways?.[colorwayName],
    });
  }, [colorwayName, theme]);

  return (
    <MuiThemeProvider theme={muiTheme}>
      {/*
      We only want to mount one set of CSS overrides in the document when this
      component is mounted at the global level. Here is a good example:
      https://github.com/mui/material-ui/issues/33332#issuecomment-1175667736
      */}
      {!existingBrandTheme && <CssBaseline />}
      <ColorwayContext.Provider value={colorwayName}>
        <BrandThemeContext.Provider value={theme}>
          {children}
        </BrandThemeContext.Provider>
      </ColorwayContext.Provider>
    </MuiThemeProvider>
  );
}

export { useTheme };
