"use client";
import { Header } from "@xenia-libs/resident-app-ui/header";
import { SideNav } from "@xenia-libs/resident-app-ui/side-nav";
import { useNavStore } from "@xenia-libs/resident-app-ui/stores/navigation.store";
import { PropsWithChildren, useCallback } from "react";
import { useRoutes } from "../hooks/use-routes";
import { Box } from "@xenia-libs/xenia-ui/layout";
import { useMuiTheme } from "@xenia-libs/xenia-ui/theme/components/theme-provider";
import { useMediaQuery } from "@xenia-libs/hooks";

export type ClientNavigationProps = PropsWithChildren<{
  logoSrc: string | undefined;
  brandName: string;
  accountDetails: React.ReactNode;
}>;

export const HEADER_HEIGHT = "55px";

export function ClientNavigationLayout(props: ClientNavigationProps) {
  const isNavOpen = useNavStore((state) => state.isNavOpen);
  const setNavOpen = useNavStore((state) => state.setNavOpen);
  const toggleNavOpen = useNavStore((state) => state.toggleNavOpen);
  const theme = useMuiTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("mobile"));

  const handleRouterClick = useCallback(() => {
    setNavOpen(false);
  }, [setNavOpen]);

  const { routeGroups } = useRoutes({
    onClick: handleRouterClick,
  });

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.up("mobile")]: {
          display: "flex",
          width: "100svw",
          height: "100svh",
        },
      })}
    >
      <SideNav top={HEADER_HEIGHT} routeGroups={routeGroups} isOpen={isNavOpen}>
        {props.accountDetails}
      </SideNav>

      <Box
        flex={1}
        sx={(theme) => ({
          [theme.breakpoints.up("mobile")]: {
            overflow: "auto",
          },
        })}
      >
        <Header
          height={HEADER_HEIGHT}
          logoSrc={props.logoSrc}
          brandName={props.brandName}
          onMenuClick={toggleNavOpen}
          isNavOpen={isNavOpen}
        />

        <Box
          component="main"
          p={4}
          maxWidth="1100px"
          mx="auto"
          inert={isNavOpen && !isDesktop}
          sx={(theme) => ({
            [theme.breakpoints.up("mobile")]: {
              px: 12,
            },
          })}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
