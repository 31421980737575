import { useEffect } from "react";

export function useElementAttributes(
  element: Element | null | undefined,
  attributes: Record<string, string | null>,
) {
  useEffect(() => {
    if (!element) {
      return;
    }

    Object.entries(attributes).forEach(([key, value]) => {
      if (value === null) {
        element.removeAttribute(key);
      } else {
        element.setAttribute(key, value);
      }
    });
  }, [attributes, element]);
}
