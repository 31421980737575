import { createContext as createReactContext, use } from "react";

// Inspired by https://github.com/chakra-ui/chakra-ui/blob/491035070149b17a61625f9f48647ae98513761f/packages/react/src/create-context.ts

export interface CreateContextOptions<T> {
  hookName: string;
  providerName: string;
  defaultValue?: T;
}

/**
 * A wrapper around React's createContext hook that provides a more
 * user-friendly API for creating contexts. This solves the common
 * problem of forgetting to wrap a component in a provider.
 *
 * @param options - The options for creating the context.
 * @returns A tuple containing the context and the hook for using the context.
 * @example
 * const [Context, useContext] = createContext<ContextShape>({
 *   hookName: "useContext",
 *   providerName: "ContextProvider",
 * });
 *
 * // providers.tsx or similar
 * <Context value={value}>{children}</Context>
 *
 * // component.tsx
 * const value = useContext();
 */
export function createContext<T = unknown>({
  hookName,
  providerName,
  defaultValue,
}: CreateContextOptions<T>) {
  const Context = createReactContext(defaultValue);
  Context.displayName = providerName;

  const useContext = () => {
    const context = use(Context);

    if (!context) {
      const error = new Error(
        `${hookName} returned \`undefined\`. Seems you forgot to wrap component within ${providerName}`,
      );
      error.name = "ContextError";
      Error.captureStackTrace?.(error, useContext);
      throw error;
    }

    return context;
  };

  return [Context, useContext] as const;
}
