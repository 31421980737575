import { CustomPaletteColors, GetThemeComponents } from "../theme/theme.types";
import { getFontWeight } from "../theme/utils/get-font-weight";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides extends CustomPaletteColors {}
}

export const buttonTheme: GetThemeComponents<"MuiButton"> = ({ theme }) => ({
  MuiButton: {
    defaultProps: {
      variant: "contained",
      disableElevation: true,
      color: "interactive",
    },
    styleOverrides: {
      outlined: {
        borderWidth: "2px",
        ":hover": {
          borderWidth: "2px",
        },
      },
      root: ({ theme: muiTheme }) => ({
        borderRadius: muiTheme.shape.buttonBorderRadius,
        textTransform: "inherit",
        fontWeight: getFontWeight(theme.buttonFontWeight),
      }),
    },
  },
});
